import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import Layout from "../components/layout";
import SEO from "../components/seo";
import FeaturedJobs from "../components/featuredjobs";
import db from "../components/firestore";
import { doc, getDoc } from "firebase/firestore/lite";
import Loading from "../components/loading";
import flag from "country-code-emoji";
import dateToText from "../components/datetext";
import ReactMarkdown from 'react-markdown';
import { renderToString } from 'react-dom/server';
import countryName from "../components/countryName";
import Tags from "../components/tag";
import ApplyNow from "../components//applynow";
import DatePosted from "../components/dateposted";
import { companyLink } from "../components/links";
import thirtyDaysAgoMillis from "../components/datehelper";
import cms from "../components/cms/cms";

// TODO MOVE TO SEPARATE COMPONENT
const Remoteness = ({ remote }) => {
  if (remote === "on_site") return <></>;
  else if (remote === "remote") {
    return <a href="/locations/remote" rel="noopener noreferrer" className="px-2 py-1 inline-flex leading-5 font-semibold rounded-full bg-teal-300 hover:bg-teal-200 text-teal-900">
      Remote
    </a>
  } else {
    return <span className="px-2 py-1 flex-shrink-0 leading-5 font-semibold rounded-full bg-teal-300 text-teal-900">
      Partially Remote
    </span>;
  }
}

const markdown = (description, cn) => <ReactMarkdown className={cn}>{description}</ReactMarkdown>

const FeaturedJobPage = ({ location }) => {

  const [featuredJob, setFeaturedJob] = useState(null);
  const [loading, setLoading] = useState(true);

  const path = location.pathname
  const isTopLevelPage = path === "/featured-jobs" || path === "/featured-jobs/"
  const getId = () => path.slice(-20)

  useEffect(() => {
    if (!isTopLevelPage) {
      getDoc(doc(db, "featured_jobs", getId()))
        .then(snapshot => {
          setFeaturedJob(snapshot.data());
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  const JobActivity = () => featuredJob && (featuredJob.date < thirtyDaysAgoMillis()) ? <div className="bg-gray-900 border-b border-gray-500">
    <div className="px-4 py-4 sm:px-6">
      <div className="flex items-center justify-center text-gray-300">
        <svg className="flex-shrink-0 w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        <p className="font-medium text-center px-2">
          This job post is more than thirty days old. The position is likely filled by now.
        </p>
      </div>
    </div>
  </div> : <></>

  const FeaturedJob = () =>
    <div className={`bg-gray-900 shadow-lg overflow-hidden sm:rounded-md ${featuredJob.featuredCompany ? "bg-gradient-to-br from-gray-900 to-teal-800" : ""}`} >
      <JobActivity />
      <div className="px-4 pb-4 sm:px-6">
        <div className="flex-shrink-0 flex -ml-2 py-4 overflow-x-auto">
        <Remoteness remote={featuredJob.remote} />
          {
            featuredJob.featuredCompany ?
              <a
                href={companyLink(featuredJob.featuredCompany)} eventCategory="Featured Company" eventAction="Click" eventLabel={featuredJob.featuredCompany}
                className="inline-flex items-center flex-shrink-0 leading-5 px-2 py-1 font-semibold rounded-full bg-yellow-300 hover:bg-yellow-200 text-yellow-900 ml-2">
                <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path></svg>
                <span>Featured Company</span>
              </a> : <></>
          }
          {
            featuredJob.featuredCompany ?
              <a
                href={companyLink(featuredJob.featuredCompany)} eventCategory="Featured Company" eventAction="Click" eventLabel={featuredJob.featuredCompany}
                className="inline-flex items-center flex-shrink-0 leading-5 px-2 py-1 font-semibold rounded-full bg-red-500 hover:bg-red-400 text-red-900 ml-2">
                <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path></svg>
                <span>Community Sponsor</span>
              </a> : <></>
          }
          
        </div>

        <div className="sm:flex sm:items-center sm:justify-between">
          <div>
            <h1 className="sm:mb-4 text-xl leading-5 font-bold text-red-500">
              {featuredJob.title}
            </h1>
            <div className="mt-2">
              <div className="sm:flex">
                {featuredJob.featuredCompany ?
                  <a
                    href={companyLink(featuredJob.featuredCompany)} eventCategory="Company Title" eventAction="Click" eventLabel={featuredJob.featuredCompany} className="flex flex-shrink-0 items-center font-bold leading-5 text-gray-200">
                    {featuredJob.image ? <img className="h-5 w-5 mr-2" src={featuredJob.image} alt={featuredJob.company} /> : <></>}
                    <h2>{featuredJob.company}</h2>
                    <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
                  </a> : <div className="flex flex-shrink-0 items-center font-bold leading-5 text-gray-200">
                    {featuredJob.image ? <img className="h-5 w-5 mr-2" src={featuredJob.image} alt={featuredJob.company} /> : <></>}
                    <h2>{featuredJob.company}</h2>
                  </div>
                }
                {(featuredJob.salaryFrom && featuredJob.salaryTo) ?
                  <div className="sm:ml-4 mt-2 flex items-center leading-5 text-gray-300 sm:mt-0 sm:border-l sm:pl-4">
                    {featuredJob.currency + featuredJob.salaryFrom} - {featuredJob.currency + featuredJob.salaryTo}
                  </div> : <></>
                }
                <div className="sm:ml-4 mt-2 flex items-center leading-5 text-gray-300 sm:mt-0 sm:border-l sm:pl-4">
                  <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-300" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                  </svg>
                  <span>{featuredJob.location}</span>
                </div>
                <div className="sm:mx-4 mt-2 flex items-center leading-5 text-gray-300 sm:mt-0 sm:border-l sm:pl-4">
                  <svg className="sm:hidden flex-shrink-0 mr-1.5 h-5 w-5 text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path></svg>
                  <span>{countryName(featuredJob.country)}</span>
                  <span className="text-xl ml-3">{flag(featuredJob.country)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden sm:block flex-shrink-0">
            <ApplyNow link={featuredJob.link} category="Apply Now Job Detail" />
          </div>
        </div>
        <div className="sm:flex sm:items-center justify-between">
          <Tags jobTags={featuredJob.tags} />
          <DatePosted date={featuredJob.date} />
        </div>
        <div className="sm:hidden block">
          <ApplyNow link={featuredJob.link} category="Apply Now Job Detail" />
        </div>
      </div>
      <div className="px-4 sm:px-6 pt-6 sm:pb-2 border-t border-gray-500">
        {markdown(featuredJob.description, "markdown-component prose text-gray-300 min-w-full")}
      </div>
      <div className="px-4 pb-6 sm:px-6 ">
        <ApplyNow link={featuredJob.link} category="Apply Now Job Detail"/>
      </div>
    </div >

  FeaturedJob.propTypes = {
    id: PropTypes.string
  }

  const truncate = input => input.length > 120 ? `${input.substring(0, 117)}...` : input;

  const validThrough = date => {
    const validThrough = new Date();
    validThrough.setDate(date.getDate() + 90);
    return validThrough;
  }

  const currency = c => {
    if (c === "€") return "EUR"
    else if (c === "£") return "GBP"
    else return "USD"
  }

  return <Layout>
    {(!loading && featuredJob) ? <SEO
      keywords={[featuredJob.company + " jobs", `${cms.keyword} jobs`, `${cms.keyword} developer jobs`, `remote ${cms.keyword} jobs`]}
      title={`${featuredJob.company} | ${featuredJob.title}`}
      description={truncate(featuredJob.description)}
      jsonLD={{
        "@context": "https://schema.org/",
        "@type": "JobPosting",
        "title": featuredJob.title,
        "description": renderToString(markdown(featuredJob.description), ""),
        "datePosted": new Date(featuredJob.date).toISOString(),
        "validThrough": validThrough(new Date(featuredJob.date)),
        "applicantLocationRequirements": [
          {
            "@type": "Country",
            "name": featuredJob.country,
          },
        ],
        "employmentType": "FULL_TIME",
        "baseSalary": {
          "@type": "MonetaryAmount",
          "currency": currency(featuredJob.currency),
          "value": {
            "@type": "QuantitativeValue",
            "value": featuredJob.salaryTo,
            "unitText": "YEAR",
          },
        },
        "jobLocation": {
          "@type": "Place",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": featuredJob.location,
            "addressCountry": featuredJob.country,
          }
        },
        "jobLocationType": (featuredJob.remote === "remote") ? "TELECOMMUTE" : null,
        "hiringOrganization": {
          "@type": "Organization",
          "name": featuredJob.company
        },
      }}
    /> : <SEO
      keywords={[`featured ${cms.keyword} jobs`, `${cms.keyword} jobs`, `${cms.keyword} developer jobs`, `remote ${cms.keyword} jobs`]}
      title={`Featured ${cms.keyword} Jobs`}
      description={`The latest featured ${cms.keyword} jobs`}
    />}
    <div className="max-w-7xl mx-auto px-4 sm:px-6">
      <div className="sm:my-10 my-5">
        {loading ? <Loading size={10} /> :
          isTopLevelPage ?
            < FeaturedJobs /> :
            featuredJob ? <FeaturedJob /> :
              <FeaturedJobs />
        }
      </div>
    </div>
  </Layout >

}

FeaturedJobPage.propTypes = {
  location: PropTypes.object
}

export default FeaturedJobPage